/* Basis-Stile für Light Mode */
:root {
  --background-color: #ffffff;
  --text-color: #000000;
  --link-color: #007bff;
  --link-hover-color: #0056b3;
  --padding: 20px; /* Abstand vom Rand */
}

body {
  background-color: var(--background-color);
  color: var(--text-color);
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box; /* Um sicherzustellen, dass Padding und Border in der Gesamtbreite enthalten sind */
}

.container {
  padding: var(--padding);
  max-width: 800px; /* Optional: Begrenze die maximale Breite für bessere Lesbarkeit */
  margin: 0 auto; /* Zentriert die Container horizontal */
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

li {
  margin: 10px 0;
}

a {
  text-decoration: none;
  color: var(--link-color);
}

a:hover {
  text-decoration: underline;
  color: var(--link-hover-color);
}

/* Dark Mode-Stile */
@media (prefers-color-scheme: dark) {
  :root {
    --background-color: #121212;
    --text-color: #e0e0e0;
    --link-color: #bb86fc;
    --link-hover-color: #3700b3;
  }
}
